<template>
  <div>
    <a-spin :spinning="loading">
      <a-form style="margin-top: 20px;" ref="form" name="form" :model="modelRef" scrollToFirstError @finish="onSubmit" :labelCol="{span: 4}" :wrapperCol="{span: 14}">
        <a-form-item label="标题">
          {{ modelRef.title }}
          <a-button style="margin-left: 20px;" type="primary">导入word文档</a-button>
        </a-form-item>

        <a-form-item name="content" :rules="[{ required: true, message: '必填项不允许为空' }]" label="描述图文">
          <div style="position: relative;z-index: 99;">
            <Editor v-model:value="modelRef.content" :configSetting="{ disabled: isSee }"></Editor>
          </div>
        </a-form-item>

        <a-row>
          <a-col :offset="6">
            <a-button v-if="!isSee" type="primary" html-type="submit">提交</a-button>
            <a-button style="margin-left: 20px;" @click="onBack">返回</a-button>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </div>
</template>

<script>
import Editor from '@/components/editor/editor';
export default {
  components: { Editor },
  props: {
    isSee: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      modelRef: {
        title: '手册内容',
        content: ''
      }
    }
  },
  created() {
  },
  methods: {
    onBack() {
      this.$emit('back');
    },
    onSubmit() {

    },
  }
}
</script>

<style>
</style>
